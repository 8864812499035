import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import GTPRGameMain from './Components/GTPRGameMain'

//@ts-ignore
const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    //@ts-ignore
    graphqlErrors.map(({ message, location, path }) => {
      alert(`Graphql error ${message}`)
    })
  }
})

const link = from([errorLink, new HttpLink({ uri: 'http://localhost:3001/graphql' })])

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
})

function App() {
  return (
    <ApolloProvider client={client}>
      <GTPRGameMain />
    </ApolloProvider>
  )
}

export default App
