import React, { useEffect, useState } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { LOAD_THREE_LANGUAGES } from '../GraphQL/Queries'
import Highlight from 'react-highlight'
import { Button } from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import styled from 'styled-components'
import SyntaxHighlighter from 'react-syntax-highlighter'
import mockData from '../mockData'

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #121212;
  width: 100%;
  height: 100vh;
`

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  gap: 20px;
`
const SH = styled(SyntaxHighlighter)`
  color: black;
  background-color: white;
  width: 80%;
  height: 60%;
  overflow: scroll;
`
const ChoiceButtons = styled(Button)`
  margin-bottom: 30px;
  margin-right: 10px;
`

type CodeObj = {
  ID: string
  LANGUAGE: string
  CODE: string
  CREATION_ID: number
}

function getRandom(arr: any[], n: number) {
  return arr.sort(() => Math.random() - Math.random()).slice(0, n)
}

const getRandNumZeroToTwo = () => Math.floor(Math.random() * 3)

const chooseOneRandomCodeObj = (codeObjs: CodeObj[]) => {
  if (codeObjs) {
    const randNum = getRandNumZeroToTwo()
    return codeObjs[randNum]
  } else
    return {
      ID: '',
      LANGUAGE: '',
      CODE: '',
      CREATION_ID: 0,
    }
}

const showrightAnswerCodeObj = (codeObj: CodeObj) => {
  return codeObj ? codeObj.CODE : 'rendering'
}

const GTPRGameMain = () => {
  //USED ON VERSION WITH BACKEND
  // const [getNewData, { error, loading, data }] = useLazyQuery(LOAD_THREE_LANGUAGES, {
  //   onCompleted: data => setCurrentData(data),
  //   fetchPolicy: 'no-cache',
  // })
  // console.log('DATA: ' + data)

  //THIS LINE ONLY FOR FRONTEND VERSION
  const [data, setData] = useState([])
  const [currentData, setCurrentData] = useState([])
  const [rightPoints, setRightPoints] = useState(0)
  const [wrongPoints, setWrongPoints] = useState(0)
  const [codeObjs, setCodeObjs] = useState([])
  const [currentPushedButton, setCurrentPushedButton] = useState('default')
  const [hasPlayerWon, setHasPlayerWon] = useState(false)
  const [rightAnswerCodeObj, setRightAnsweCodeObj] = useState({
    ID: '',
    LANGUAGE: '',
    CODE: '',
    CREATION_ID: 0,
  })

  const onButtonClick = (val: CodeObj) => {
    if (val?.LANGUAGE && rightAnswerCodeObj?.LANGUAGE) {
      setCurrentPushedButton(val.LANGUAGE)
      if (val.LANGUAGE === rightAnswerCodeObj.LANGUAGE) {
        setHasPlayerWon(true), setRightPoints(rightPoints + 1)
      } else {
        setHasPlayerWon(false), setWrongPoints(wrongPoints + 1)
      }
    }
  }

  //TODO: Refactor to async/await promises

  useEffect(() => {
    //REMOVE SETDATA IN BACKEND VERSION
    //BACKEND VERSION getNewData()
    const data = getRandom(mockData, 3)
    //@ts-ignore
    setData(data)
  }, [])

  useEffect(() => {
    if (data) {
      //@ts-ignore
      setCurrentData(data)
    }
  }, [data])

  useEffect(() => {
    if (data) {
      //@ts-ignore
      setCodeObjs(data)
    }
  }, [currentData])

  useEffect(() => {
    if (currentData) {
      setRightAnsweCodeObj(chooseOneRandomCodeObj(codeObjs))
    }
  }, [codeObjs])

  useEffect(() => {
    if (hasPlayerWon) {
      //BACKEND VERSION getNewData()
      const data = getRandom(mockData, 3)
      //@ts-ignore
      setData(data)
      setHasPlayerWon(false)
    }
  }, [hasPlayerWon])

  const generateButtons = (codeObjs: CodeObj[]) => {
    if (codeObjs && codeObjs.length > 0) {
      return codeObjs.map((val: CodeObj) => {
        return (
          <ChoiceButtons color='secondary' onClick={() => onButtonClick(val)}>
            {val.LANGUAGE}
          </ChoiceButtons>
        )
      })
    } else {
      return 'rendering'
    }
  }

  return (
    <MainDiv>
      <h3>Guess the programming language</h3>
      <br />
      <ButtonDiv>
        <Button color='success'>{rightPoints}</Button>
        <Button color='danger'>{wrongPoints}</Button>
      </ButtonDiv>
      <br />
      <div>{generateButtons(codeObjs)}</div>
      <SH>{showrightAnswerCodeObj(rightAnswerCodeObj)}</SH>
    </MainDiv>
  )
}

export default GTPRGameMain
